<template>
    <div class="min-w-min">
        <div class="grid">
            <div class="hidden md:inline-flex md:col-6 lg:col-6 xl:col-6 bg-yellow-400">
              <div class="row">
                <!--begin col-md-12 -->
                <div class="col-md-12 text-center">
                <div class="section-title text-1xl font-bold">{{gsupport.title}}</div>
                </div>
                <!--end col-md-12 -->
                <!--begin col-md-12 -->
                <div class="col-md-12" v-html="gsupport.desc">
                </div>
            <!--end col-md-12 -->
            <div class="col-md-12 text-center box-border-dot">
                <div class="text-3xl font-bold border-none border-bottom-1 p-2">{{gsupport.titleht}}</div>
                <div class="mb-1 text-1xl font-bold">{{gsupport.titlephone}}</div>
                <div class="mb-1 text-2xl font-bold text-pink-700"><i class="pi pi-phone"></i> {{gsupport.phone}}</div>
                <div class="mb-1 text-1xl font-bold">{{gsupport.titleemail}}</div>
                <div class="mb-1 text-2xl font-bold text-pink-700"><i class="pi pi-envelope"></i> {{gsupport.email}}</div>
            </div>
            </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6 xl:col-6 max-w-screen">
               <Cform :fcode="'a123'" @utracking="utracking" @senddone="senddone" :gemail="esend" />
            </div>
        </div>
    </div>
</template>

<script>
import Cform from '@/components/cform'
import { ctntrack } from '@/api/tracking'
import { lDatasource, getItemD } from '@/api/datasource'
export default {
  components: { Cform },
  props: {
    psend: { type: String, default: '' },
    esend: { type: String, default: '' }
  },
  data () {
    return {
      sender: {},
      gsupport: { title: '', desc: '', titleht: '', titlephone: '', phone: '', titleemail: '', email: '' },
      showMessage: 0,
      lQuery: {
        pageid: '',
        pagetitle: '',
        lg: 'vi'
      }
    }
  },
  watch: {
    psend: function (newvl, oldvl) {
      this.lQuery.pageid = newvl.pageid
      this.lQuery.pagetitle = newvl.pagetitle
    },
    esend: function (newvl, oldvl) {
      this.email = newvl
    }
  },
  created () {
    this.lQuery = this.psend
    this.email = this.esend
    this.lQuery.lg = sessionStorage.getItem('st-lg')
    this.gtdata()
  },
  methods: {
    senddone (vl) {
      this.showMessage = true
      this.sender = vl
      sessionStorage.setItem('st-name', vl.name)
      sessionStorage.setItem('st-email', vl.email)
      getItemD({ tbcode: 'amessage', code: '1' }).then((res) => {
        var tt = 'title'
        var nt = 'notice'
        var tmpdata = {}
        if (this.lQuery.lg === 'vi') {
          tt = 'title'
          nt = 'notice'
        } else {
          tt = 'title' + this.lQuery.lg
          nt = 'notice' + this.lQuery.lg
        }
        tmpdata = res.data.ldata
        var msgdt = { title: tmpdata[nt], data: tmpdata[tt] }
        this.$emit('showMessage', msgdt, 1)
      })
      this.close(3)
    },
    gtdata () {
      this.lQuery.code = 'thongtinlienhe'
      lDatasource(this.lQuery).then((res) => {
        if (res) {
          var tmpvl = {}
          res.data.rdata.forEach((item) => {
            tmpvl = item.ldata
          })
          this.gsupport = tmpvl
        }
      })
    },
    close (vl) {
      this.$emit('close', vl)
    },
    utracking (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var gtdata = { rsession: gss, pageid: this.lQuery.pageid, pagetitle: this.lQuery.pagetitle, elementid: vl }
      ctntrack(gtdata)
    }
  }
}
</script>
